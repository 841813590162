import { useQuery } from '@tanstack/vue-query'
import { getApi } from "./api";
export default (userId: number, accountId: number|null = null) => useQuery({
    queryKey: ['user-devices', userId, accountId],
    queryFn: () => {
        const { api, baseURL } = getApi();
        const params = accountId ? { account: accountId } : {};
        return api.get(`api/users/${userId}/devices`, {baseURL, params }).then(res => res.data.data);
    },
})
