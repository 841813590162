<template>
  <div class="mb-4">
    <p class="mb-2 text-xl text-gray-1000">Send notifications when</p>
    <p v-if="isB2B && isAdminRole" class="text-gray-1000">
      Device Notifications
    </p>
    <VCheckbox v-model="form.notify_geofence_enter_exit">
      <span class="flex-1">Device enters/exits a geofence</span>
    </VCheckbox>
    <VCheckbox v-model="form.notify_low_charge">
      <span class="flex-1">Device has a low battery</span>
    </VCheckbox>
    <VCheckbox v-model="form.notify_device_alert">
      <span class="flex-1">
        Device sends tamper, temperature, movement, etc. alert
        <i>(supported alerts depend on hardware)</i>
      </span>
    </VCheckbox>
    <VCheckbox v-model="form.notify_shipment_begin_end_late">
      <span class="flex-1"> Shipment begin/end/late </span>
    </VCheckbox>

    <template v-if="isB2B && isAdminRole">
      <p class="mt-2 text-gray-1000">Financial Notifications</p>
      <VCheckbox v-model="form.receive_subscription_notifications">
        <span class="flex-1">Subscription Notifications</span>
      </VCheckbox>
      <VCheckbox v-model="form.receive_billing_notifications">
        <span class="flex-1">Order and Billing Notifications</span>
      </VCheckbox>
    </template>
  </div>
  <div class="mb-4">
    <p class="mb-2 text-xl text-gray-1000">Send notifications via</p>
    <VCheckbox v-model="form.notify_via_email">
      <span class="flex-1">Email to {{ user.email }}</span>
    </VCheckbox>
    <VCheckbox v-model="form.notify_via_mobile">
      <span class="flex-1">Push notifications on mobile application</span>
    </VCheckbox>
    <div v-if="user.phone">
      <VCheckbox v-model="form.notify_via_sms">
        <span class="flex-1">SMS to {{ user.phone }}</span>
      </VCheckbox>
      <div class="mb-3 block pl-[22px]">
        <small>
          You agree to receive SMS notifications from Logistimatics. Message and
          data rates may apply. One message is sent per notification. Reply HELP
          for help; STOP to opt out.
          <a
            target="_blank"
            href="https://logistimatics.com/terms-of-service/"
            class="text-red-400 hover:text-red-700 hover:underline"
            >Terms of Service</a
          >
          and
          <a
            target="_blank"
            href="https://logistimatics.com/privacy/"
            class="text-red-400 hover:text-red-700 hover:underline"
            >Privacy Policy</a
          >.
        </small>
      </div>
    </div>
  </div>
  <div>
    <VButton
      type="submit"
      variant="primary"
      class="w-48"
      @click="handleSubmit"
      :isLoading="isSubmitting"
      loadingText="Updating..."
      :isDisabled="isSubmitting"
    >
      Update notifications
    </VButton>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import each from 'lodash/each';
import map from 'lodash/map';
import get from 'lodash/get';
import VButton from '@/components/ui/VButton.vue';
import VCheckbox from '@/components/ui/VCheckbox.vue';
import { useToast } from 'vue-toastification';
import { COMMON_UPDATE_SUCCESS } from '@/config/constants';

export default {
  components: {
    VButton,
    VCheckbox,
  },

  data() {
    return {
      isSubmitting: false,
      form: {
        notify_geofence_enter_exit: false,
        notify_low_charge: false,
        notify_device_alert: false,
        notify_shipment_begin_end_late: false,
        receive_subscription_notifications: true,
        receive_billing_notifications: true,
        notify_via_email: false,
        notify_via_mobile: false,
        notify_via_sms: false,
      },
    };
  },

  mounted() {
    each(this.form, (defaultValue, code) => {
      this.form[code] = Boolean(+get(this.userSetting, code, defaultValue));
    });
  },

  methods: {
    ...mapActions({
      fetchSettingsByAccountId: 'settings/fetchByAccountId',
      storeSettingsUnderAccountId: 'settings/storeUnderAccountId',
    }),
    handleSubmit() {
      const toast = useToast();

      this.isSubmitting = true;
      this.storeSettingsUnderAccountId({
        accountId: this.accountId,
        params: {
          settings: this.formattedSettings,
        },
      })
        .then(() => {
          toast.success(COMMON_UPDATE_SUCCESS);
          this.isSubmitting = false;
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      accountId: 'auth/accountId',
      userSetting: 'settings/user',
      isB2B: 'auth/isB2B',
      isAdminRole: 'auth/isAdminRole',
    }),
    formattedSettings() {
      return map(this.form, (value, code) => {
        return { code, value: Boolean(+value) };
      });
    },
  },
  watch: {
    accountId(accountId) {
      this.fetchSettingsByAccountId({ accountId });
    },
  },
};
</script>

<style></style>
