<template>
  <NoData
    v-if="bleAnchors.length === 0"
    title="There are no Anchors"
    subtitle="Your anchor list is empty"
  >
    <UIButtonNew icon="SquaresPlusIcon" @click="handleCreateClick">
      Add Anchor
    </UIButtonNew>
  </NoData>
  <div class="h-full" v-else>
    <CommonSearchBarSafeArea />
    <div class="h-12 bg-lgmx_gray-100"></div>
    <DynamicScroller
      class="h-[calc(100%-48px)] sm:h-[calc(100%-64px)]"
      :items="props.bleAnchors"
      :min-item-size="98"
      key-field="id"
      listClass="ds-ble-anchor-list"
      itemClass="ds-ble-anchor-list-item"
    >
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[item.name]"
          :data-index="index"
        >
          <div class="border-b border-gray-100">
            <div class="bg-white hover:bg-gray-100">
              <div class="flex py-[15px] pl-6 pr-[48px] md:pl-12 md:pr-[56px]">
                <BleAnchorListItem
                  class="w-full overflow-hidden"
                  :ble-anchor="item"
                  :edit-item-id="editItemId"
                  @setEditItemId="setEditItemId"
                  @resetEditItemId="resetEditItemId"
                />
              </div>
            </div>
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
  <CommonBottomButtonSection v-if="$can('manage', 'Anchors') && isBottomButton">
    <UIButtonNew
      class="w-full"
      icon="SquaresPlusIcon"
      @click="handleCreateClick"
    >
      New Anchor
    </UIButtonNew>
  </CommonBottomButtonSection>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import NoData from '@/components/_common/NoData.vue';
import CommonBottomButtonSection from '@/components/_common/CommonBottomButtonSection.vue';
import BleAnchorListItem from './BleAnchorListItem.vue';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import { useSetItemIdOnDynamicList } from '@/use/use-set-item-id-on-recycle-list';
import * as events from '@/events';

const props = defineProps({
  bleAnchors: Array,
});

const store = useStore();
const isBottomButton = computed(
  () => store.getters['bleAnchor/isBottomButton'],
);

const { editItemId, setEditItemId, resetEditItemId } =
  useSetItemIdOnDynamicList('ble-anchor');

function handleCreateClick() {
  store.dispatch('modal/open', {
    name: 'GeofenceCreateBleAnchorModal',
  });
}

onMounted(() => {
  events.trigger(events.names.ALL_BLE_ANCHORS_VIEWED);
});
</script>

<style>
.ds-ble-anchor-list {
  @apply !overflow-visible;
}
</style>
