<template>
  <ModalLayout title="Edit User">
    <EditUserPermissionsModal
      :userId="+params.userId"
      :accountId="+accountId"
      @close="$emit('close')"
      @save="handleEditUserPermissions"
      :isSaving="isSavingPermissions"
    />
  </ModalLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import ModalLayout from '@/layouts/ModalLayout.vue';
import { EditUserPermissionsModal } from '@gpx-spa/ui';

export default {
  components: {
    EditUserPermissionsModal,
    ModalLayout,
  },

  emits: ['close'],
  props: ['params'],

  data() {
    return {
      isSavingPermissions: false,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      activeModal: 'modal/active',
    }),
  },

  methods: {
    ...mapActions({
      updateUserPermissions: 'user/updateUserPermissions',
      fetchMe: 'auth/fetchMe',
    }),
    handleEditUserPermissions({ role, devices, email }) {
      const toast = useToast();
      this.isSavingPermissions = true;

      this.updateUserPermissions({
        params: {
          account_id: this.accountId,
          devices: devices.map((id) => ({ id, permissions: '[]' })),
          role,
          email,
        },
      })
        .then(() => {
          toast.success('The user permissions were successfully updated');
          this.activeModal.params.onSuccess();
          this.$emit('close');
          this.fetchMe();
        })
        .catch((e) => {
          if (e.response) {
            toast.error(e.response.data.message);
          } else {
            console.log(e);
            toast.error('An error occurred. Please try again later.');
          }
        })
        .finally(() => (this.isSavingPermissions = false));
    },
  },
};
</script>
