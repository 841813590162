<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { map, find, get } from "lodash";
import {computed, ref, watch} from "vue";
import {getAccountUser, getUserDevices} from "@gpx-spa/queries";

import Spinner from "../elements/Spinner.vue";
import Button from "../elements/Button.vue";
import PermissionPicker from "./PermissionPicker.vue";

const props = defineProps(['accountId', 'userId', 'isSaving'])
const emit = defineEmits(['close', 'save'])

const { data: user, isFetching } = getAccountUser(props.accountId, props.userId);
const { data: userDevices } = getUserDevices(props.userId, props.accountId);
const savedRole = computed(() => get(user.value?.account_roles, props.accountId));

const savedDevices = computed(() => map(userDevices.value, (device) => device.id));
const account = computed(() => find(user.value?.accounts, {id: props.accountId}));

const role = ref(savedRole.value || 'all');
const devices = ref(savedDevices.value || []);
watch(savedRole, () => role.value = savedRole.value);
watch(savedDevices, () => devices.value = savedDevices.value);

</script>

<template>
  <div class="rounded-t-[10px] bg-white pt-[41px]">
    <div
        @click.prevent="emit('close')"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
    >
      <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
      />
    </div>
    <div
        class="mx-[24px] border-b border-b-gpx_gray-800 pb-[18px] sm:mx-[40px]"
    >
      <div class="mb-[2px] text-gpx_sm font-medium text-gpx_gray-200">
        Editing {{ user?.first_name || 'User' }} Permissions
      </div>
      <div
          v-if="account"
          class="text-gpx_2xl font-semibold text-lms_black-500"
      >
        {{ account.title }}
      </div>
    </div>
    <div class="px-[24px] sm:px-[40px]">
      <div class="mt-[30px]">
        <div class="mb-[3px] text-gpx_base font-semibold text-lms_black-400">
          User Role
        </div>
        <div class="text-gpx_sm text-gpx_gray-100">
          Grant user access to all or a specific devices.
        </div>
      </div>
      <div class="mt-[30px]" v-if="isFetching">
        <Spinner class="mr-[5px] h-[14px] w-[16px] self-center" />
      </div>
      <PermissionPicker v-else
          :accountId="accountId"
          v-model:role="role"
          v-model:device-ids="devices"
      ></PermissionPicker>
    </div>
    <div class="rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]">
      <Button
          :loading="isSaving"
          :disabled="isSaving"
          :label="isSaving ? 'Saving' : 'Save Settings'"
          @click="() => $emit('save', {role, devices, email: user.email})"
      />
    </div>
  </div>
</template>