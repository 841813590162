import '@/events/listeners';
import '@/events/listeners/mixpanel';
import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import NProgress from 'nprogress';
import store from './store';
import router from './router';
import VTooltip from 'v-tooltip';
import mixpanel from 'mixpanel-browser';
import { createPinia } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

import vClickOutside from 'click-outside-vue3';
import Toast from 'vue-toastification';
import {
  getAccountIdFromURL,
  getAccountRedirectionUrl,
} from '@/utils/b2b-account';
import { redirectToWithToken, getTokenFromURL } from '@/utils/token';
import {
  removeImitateTokenIfNeeded,
  storeImitateFrom,
  storeImitatingAdmin,
} from '@/utils/imitate';
import { VITE_MIXPANEL_TOKEN, VITE_VITALLY_TOKEN } from '@/config/environment';

import App from './App.vue';
import { registerComponents } from './components/register-components';
import { registerIcons } from './components/register-icons';

import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'v-tooltip/dist/v-tooltip.css';
import 'vue-toastification/dist/index.css';
import 'nprogress/nprogress.css';
import './assets/primevue/primevue.css';
import './assets/tailwind.css';
import './assets/overrides.css';
import './assets/heroicons.css';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { startBugsnag } from './bugsnag';
import { exposeRouter } from './helpers';
import { init } from '@/composables/useVitally';
import { abilitiesPlugin } from '@casl/vue';
import defineAbilities from './defineAbilities';
import { initializeQueries } from '@gpx-spa/queries';
import service from '@/services';

const initVitally = (account, user) => {
  const imitatingAdmin = useLocalStorage('imitating_admin', null);
  if (
    !account.uses_gpx_app ||
    !account.included_in_cs ||
    imitatingAdmin.value
  ) {
    return;
  }

  init(VITE_VITALLY_TOKEN, account, user);
};

const startApp = () => {
  const bugsnagVue = startBugsnag();
  const app = createApp(App);
  const pinia = createPinia();

  app
    .use(bugsnagVue)
    .use(store)
    .use(pinia)
    .use(abilitiesPlugin, defineAbilities(), { useGlobalProperties: true })
    .use(router)
    .use(PrimeVue)
    .use(VTooltip)
    .use(vClickOutside)
    .use(Toast, {
      transition: 'Vue-Toastification__fade',
      timeout: 3000,
      draggable: true,
      draggablePercent: 0.25,
    });
  exposeRouter(router);
  initializeQueries(app, service);

  registerComponents(app);
  registerIcons(app);
  app.mount('#app');
};

const initApp = async () => {
  mixpanel.init(VITE_MIXPANEL_TOKEN, {
    autotrack: false,
    track_pageview: false,
    debug: import.meta.env.DEV,
    disable_persistence: true,
  });

  NProgress.configure({ showSpinner: false, speed: 350 });

  storeImitateFrom();
  storeImitatingAdmin();

  try {
    removeImitateTokenIfNeeded();
    const token = getTokenFromURL();

    if (token) {
      await store.dispatch('auth/evaluateTemporaryToken', {
        temporaryToken: token,
      });
    }

    const accountId = getAccountIdFromURL();
    await store.dispatch('auth/fetchMe', { accountId });
    const account = store.getters['auth/account'];
    const user = store.getters['auth/user'];
    const redirectionUrl = getAccountRedirectionUrl(account);
    defineAbilities();
    if (!redirectionUrl) {
      if (user?.id) {
        initVitally(account, user);
      }
      startApp();
      return;
    }

    const temporaryToken = await store.dispatch('auth/fetchTemporaryToken');

    window.location.replace(
      redirectToWithToken(redirectionUrl, temporaryToken, account),
    );
  } catch (e) {
    window.location.href = '/login';
  }
};

initApp();
