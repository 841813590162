<template>
  <div
    class="pl-6 pr-4 sm:w-4/12 sm:border-r sm:border-lgmx_gray-100 md:pl-12 md:pr-6"
  >
    <ShipmentDetailsNavBar class="h-[64px] sm:h-[48px] sm:translate-y-2" />
  </div>

  <div class="hidden justify-between px-4 py-2 sm:flex sm:w-8/12 md:pr-12">
    <div class="flex gap-2">
      <UIButtonNew v-if="$can('manage', 'Shipments')" size="sm" variant="tertiary" @click="onEditClick">
        Edit
      </UIButtonNew>
      <CommonAutoRefreshButton
        :is-enabled="isEnabled"
        @enable="enable"
        @disable="disable"
      />
    </div>
    <div class="flex justify-between gap-x-2">
      <UITooltip text="Download these points">
        <CommonDownloadIconButton type="shipments" @click="downloadShipment" />
      </UITooltip>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import NProgress from 'nprogress';
import ShipmentDetailsNavBar from './ShipmentDetailsNavBar.vue';
import { useAutoRefresh } from '@/use/auto-refresh';
import CommonAutoRefreshButton from '@/components/_common/CommonAutoRefreshButton.vue';
import CommonDownloadIconButton from '@/components/_common/CommonDownloadIconButton.vue';
import { getFileName } from '@/utils/blob';

const store = useStore();
const router = useRouter();
const route = useRoute();

const accountId = store.getters['auth/accountId'];
const id = +route.params.id;
const shipmentById = store.getters['shipment/byId'];
const shipment = computed(() => shipmentById(id));

const fetchShipmentByAccountIdAndId = (params) =>
  store.dispatch('shipment/fetchByAccountIdAndId', params);

const refreshAll = () => {
  NProgress.start();

  const refreshFns = [fetchShipmentByAccountIdAndId({ accountId, id })];

  const promise = Promise.all(refreshFns);
  promise.finally(() => {
    NProgress.done();
  });
  return promise;
};

const { enable, disable, isEnabled } = useAutoRefresh(refreshAll);

const downloadShipment = () => {
  store.dispatch('shipment/download', {
    accountId,
    id,
    fileName: getFileName({
      prefix: `shipment_${shipment.value.name}`,
    }),
    params: {
      download: true,
      offset: new Date().getTimezoneOffset(),
    },
  });
};

function onEditClick() {
  router.push({
    name: 'ShipmentEditView',
    params: { id: route.params.id },
  });
}
</script>
