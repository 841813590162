<template>
  <div>
    <div class="flex">
      <div>
        <div class="text-[15px] font-semibold text-lms_black-300">
          {{ props.geofence.name }}
        </div>
        <div class="text-[13px] text-lgmx_gray-900">
          {{ props.geofence.address }}
        </div>
      </div>
      <div class="ml-auto">
        <div class="flex">
          <UIIconButton
            v-if="getUIButtonActions().length > 0"
            class="absolute right-[9px] top-[11px] ml-[6px] md:right-[16px]"
            icon="EllipsisVerticalIcon"
            @click="$emit('setEditItemId', props.geofence.id)"
          >
            <UIActions
              v-if="props.editItemId === props.geofence.id"
              :id="'geofence-actions-' + geofence.id"
              v-click-outside="() => $emit('resetEditItemId')"
              :editItemId="props.editItemId"
              :actions="getUIButtonActions()"
            />
          </UIIconButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { COMMON_DELETE_ERROR, COMMON_DELETE_SUCCESS } from '@/config/constants';
import UIIconButton from "@/components/ui/UIIconButton.vue";
import { useAbility } from '@casl/vue';

defineEmits(['setEditItemId', 'resetEditItemId']);
const props = defineProps({
  geofence: Object,
  editItemId: Number,
});
const store = useStore();
const router = useRouter();
const { can } = useAbility();
const accountId = store.getters['auth/accountId'];

function getUIButtonActions() {
  const editGeofence = {
    icon: 'PencilSquareIcon',
    label: 'Edit',
    action: {
      mode: 'fn',
      fn: (id) => {
        router.push({
          name: 'GeofenceEditView',
          params: { id: `${id}` },
        });
      },
    },
  };
  const deleteGeofence = {
    icon: 'TrashIcon',
    label: 'Delete',
    action: {
      mode: 'fn',
      fn: (id) => {
        const geofence = store.getters['geofence/byId'](id);
        store.dispatch('modal/open', {
          name: 'CommonConfirmationModal',
          params: {
            actionLabel: 'Delete',
            title: geofence.name,
            body: 'Are you sure you want to delete this geofence?',
            onOk: () => store.dispatch('geofence/delete', { accountId, id }),
            callback: () => router.replace({ name: 'GeofenceListView' }),
            successMessage: COMMON_DELETE_SUCCESS,
            errorMessage: COMMON_DELETE_ERROR,
          },
        });
      },
    },
  };
  return can('manage', 'Geofences') ? [editGeofence, deleteGeofence] : [];
}
</script>
