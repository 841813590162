import NProgress from 'nprogress';
import { createRouter, createWebHistory } from 'vue-router';
import { addDays, subDays } from 'date-fns';
import store from '@/store';
import { legacyRedirects } from './legacy-redirects';

import LoginView from '@/views/LoginView.vue';
/* Trackers */
import DevicesMapLayout from '@/layouts/DevicesMapLayout.vue';
import DeviceListView from '@/views/DeviceListView.vue';
import DeviceGroupListBar from '@/components/device/list/DeviceGroupListBar.vue';
import DeviceDetailsView from '@/views/DeviceDetailsView.vue';
import DeviceDetailsBar from '@/components/device/details/DeviceDetailsBar.vue';

/* Groups */
import GroupListView from '@/views/GroupListView.vue';

/* Geofences */
import GeofencesMapLayout from '@/layouts/GeofencesMapLayout.vue';
import GeofenceListView from '@/views/GeofenceListView.vue';
import GeofenceListBar from '@/components/geofence/list/GeofenceListBar.vue';
import GeofenceDetailsView from '@/views/GeofenceDetailsView.vue';
import GeofenceDetailsBar from '@/components/geofence/details/GeofenceDetailsBar.vue';
import GeofenceCreateView from '@/views/GeofenceCreateView.vue';
import GeofenceCreateBar from '@/components/geofence/create/GeofenceCreateBar.vue';
import GeofenceEditView from '@/views/GeofenceEditView.vue';
import GeofenceEditBar from '@/components/geofence/edit/GeofenceEditBar.vue';

/* Dashboards */
import DashboardsLayout from '@/layouts/DashboardsLayout.vue';
import DashboardsDwellView from '@/views/DashboardsDwellView.vue';
import DashboardsCustomReporstView from '@/views/DashboardsCustomReportsView.vue';
import DashboardsDwellDisclaimer from '@/components/dashboards/dwell/DashboardsDwellDisclaimer.vue';

/** Shipments */
import ShipmentsMapLayout from '@/layouts/ShipmentsMapLayout.vue';
import ShipmentListView from '@/views/Shipments/ShipmentListView.vue';
import ShipmentListBar from '@/components/shipment/list/ShipmentListBar.vue';
import ShipmentDetailsView from '@/views/Shipments/ShipmentDetailsView.vue';
import ShipmentDetailsBar from '@/components/shipment/details/ShipmentDetailsBar.vue';
import ShipmentCreateView from '@/views/Shipments/ShipmentCreateView.vue';
import ShipmentEditView from '@/views/Shipments/ShipmentEditView.vue';
import ShipmentEditBar from '@/components/shipment/edit/ShipmentEditBar.vue';
import ShipmentTrackView from '@/views/Shipments/ShipmentTrackView.vue';

/** Alerts */
import AlertsView from '@/views/AlertsView.vue';

/** Reports */
import ReportListView from '@/views/Reports/ReportListView.vue';
import ReportDetailsView from '@/views/Reports/ReportDetailsView.vue';
import ReportViewView from '@/views/Reports/ReportViewView.vue';

/** Rules */
import RulesEditView from '@/views/Rules/RulesEditView.vue';
import RulesCreateView from '@/views/Rules/RulesCreateView.vue';
import RulesManageView from '@/views/Rules/RulesManageView.vue';

/** Manage */
import ManageLayout from '@/layouts/ManageLayout.vue';
import ManageSubscriptionsView from '@/views/Manage/ManageSubscriptionsView.vue';
import ManageSubscriptionView from '@/views/Manage/ManageSubscriptionView.vue';
import ManageUsersView from '@/views/Manage/ManageUsersView.vue';
import ManageOrdersView from '@/views/Manage/ManageOrdersView.vue';
import ManageOrderView from '@/views/Manage/ManageOrderView.vue';
import ManageAddressesView from '@/views/Manage/ManageAddressesView.vue';
import ManagePaymentView from '@/views/Manage/ManagePaymentView.vue';
import ManageAPIAccessView from '@/views/Manage/ManageAPIAccessView.vue';

/** Account */
import AccountSettingsView from '@/views/AccountSettingsView.vue';

/** Approve Order */
import ApproveOrderView from '@/views/ApproveOrderView.vue';
import ApproveOrderSuccessView from '@/views/ApproveOrderSuccessView.vue';

/** Utility */
import UtilityView from '@/views/Utility/UtilityView.vue';

/** 40x */
import NotFoundView from '@/views/NotFoundView.vue';
import ForbiddenView from '@/views/ForbiddenView.vue';
import { useNextRoute } from '@/use/use-next-route';
import GroupDetailsView from '@/views/GroupDetailsView.vue';
import {
  fetchBleAnchors,
  fetchDevicesAndIccids,
  fetchGeofence,
  fetchGeofences,
  fetchGroup,
  fetchGroups,
  fetchPositionsByAccount,
  fetchDeviceById,
} from './fetchers';
import { useDevice } from '@/composables/useDevice';
import { useAbility } from '@casl/vue';

const routes = [
  {
    path: '/',
    name: 'root',
    beforeEnter: (to) => {
      const { next } = to.query;
      if (next) {
        return {
          path: next,
        };
      } else {
        return {
          name: 'DeviceListView',
        };
      }
    },
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
  },
  {
    path: '/forgot',
    name: 'PasswordForgotView',
    component: () =>
      import(
        /* webpackChunkName: "forgot" */ '../views/PasswordForgotView.vue'
      ),
  },
  {
    path: '/reset-password',
    name: 'PasswordResetView',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '../views/PasswordResetView.vue'
      ),
  },
  {
    path: '/create-account',
    name: 'CreateAccountView',
    component: () =>
      import(
        /* webpackChunkName: "createAccount" */ '../views/CreateAccountView.vue'
      ),
  },
  {
    path: '/payment-success',
    name: 'PaymentSuccessView',
    component: () =>
      import(
        /* webpackChunkName: "createAccount" */ '../views/PaymentSuccessView.vue'
      ),
  },

  /** Trackers */
  {
    path: '/accounts/:accountId',
    component: DevicesMapLayout,
    children: [
      {
        path: 'trackers/:id',
        name: 'GpxAdminDeviceDetailsView',
        components: {
          default: DeviceDetailsView,
          bar: DeviceDetailsBar,
        },
      },
    ],
  },
  {
    path: '/trackers',
    component: DevicesMapLayout,
    children: [
      {
        path: '',
        name: 'DeviceListView',
        components: {
          default: DeviceListView,
          bar: DeviceGroupListBar,
        },
      },
      {
        path: ':id',
        name: 'DeviceDetailsView',
        components: {
          default: DeviceDetailsView,
          bar: DeviceDetailsBar,
        },
      },
    ],
  },

  /** Groups */
  {
    path: '/groups',
    component: DevicesMapLayout,
    children: [
      {
        path: '',
        name: 'GroupListView',
        components: {
          default: GroupListView,
          bar: DeviceGroupListBar,
        },
      },
      {
        path: ':id',
        name: 'GroupDetailsView',
        components: {
          default: GroupDetailsView,
          bar: DeviceGroupListBar,
        },
      },
    ],
  },

  /** Geofences */
  {
    path: '/geofences',
    component: GeofencesMapLayout,
    children: [
      {
        path: '',
        name: 'GeofenceListView',
        components: {
          default: GeofenceListView,
          bar: GeofenceListBar,
        },
      },
      {
        path: ':id',
        name: 'GeofenceDetailsView',
        components: {
          default: GeofenceDetailsView,
          bar: GeofenceDetailsBar,
        },
      },
      {
        path: 'create',
        name: 'GeofenceCreateView',
        components: {
          default: GeofenceCreateView,
          bar: GeofenceCreateBar,
        },
      },
      {
        path: ':id/edit',
        name: 'GeofenceEditView',
        components: {
          default: GeofenceEditView,
          bar: GeofenceEditBar,
        },
      },
    ],
  },

  /** Dashboards */
  {
    path: '/dashboards',
    redirect: '/dashboards/dwell',
    component: DashboardsLayout,
    beforeEnter: () => {
      const { can } = useAbility();
      if (!can('view', 'Dashboard')) {
        return { name: 'DeviceListView' };
      }
    },
    children: [
      {
        path: 'dwell',
        name: 'DashboardsDwellView',
        components: {
          default: DashboardsDwellView,
          disclaimer: DashboardsDwellDisclaimer,
        },
      },
      {
        path: 'analytics',
        name: 'DashboardCustomReportView',
        components: {
          default: DashboardsCustomReporstView,
          disclaimer: DashboardsDwellDisclaimer,
        },
        beforeEnter: () => {
          if (!store.getters['auth/canViewCustomReport']) {
            return { name: 'DeviceListView' };
          }
        },
      },
    ],
  },

  /** Shipments */
  {
    path: '/shipments',
    component: ShipmentsMapLayout,
    children: [
      {
        path: '',
        name: 'ShipmentListView',
        components: {
          default: ShipmentListView,
          bar: ShipmentListBar,
        },
        meta: { requestAccountAccess: 'can_track_shipments' },
      },
      {
        path: ':id',
        name: 'ShipmentDetailsView',
        components: {
          default: ShipmentDetailsView,
          bar: ShipmentDetailsBar,
        },
        meta: { requestAccountAccess: 'can_track_shipments' },
      },
      {
        path: ':id/edit',
        name: 'ShipmentEditView',
        components: {
          default: ShipmentEditView,
          bar: ShipmentEditBar,
        },
        meta: { requestAccountAccess: 'can_track_shipments' },
      },
      {
        path: 'track/:uuid',
        name: 'ShipmentTrackView',
        components: {
          default: ShipmentTrackView,
        },
      },
    ],
  },
  {
    path: '/shipments/create',
    name: 'ShipmentCreateView',
    components: {
      default: ShipmentCreateView,
    },
    meta: { requestAccountAccess: 'can_track_shipments' },
  },

  /** Alerts */
  {
    path: '/alerts',
    name: 'AlertsView',
    component: AlertsView,
  },

  /** Reports */
  {
    path: '/reports',
    name: 'ReportListView',
    component: ReportListView,
  },
  {
    path: '/reports/:id',
    name: 'ReportDetailsView',
    component: ReportDetailsView,
  },
  {
    path: '/reports/view/:uuid',
    name: 'ReportViewView',
    component: ReportViewView,
  },

  /** Rules */
  {
    path: '/rules',
    name: 'rules.index',
    components: {
      default: RulesManageView,
    },
    meta: { requestAccountAccess: 'has_rules_engine' },
  },
  {
    path: '/rules/create',
    name: 'rules.create',
    components: {
      default: RulesCreateView,
    },
    meta: { requestAccountAccess: 'has_rules_engine' },
    beforeEnter: () => {
      const { can } = useAbility();
      if (!can('manage', 'Rules')) {
        return { name: 'rules.index' };
      }
    },
  },
  {
    path: '/rules/:id',
    name: 'rules.edit',
    components: {
      default: RulesEditView,
    },
    meta: { requestAccountAccess: 'has_rules_engine' },
    beforeEnter: () => {
      const { can } = useAbility();
      if (!can('manage', 'Rules')) {
        return { name: 'rules.index' };
      }
    },
  },

  /** Manage */
  {
    path: '/manage',
    redirect: '/manage/subscriptions',
    component: ManageLayout,
    beforeEnter: () => {
      const { can } = useAbility();
      if (!can('manage', 'Account')) {
        return { name: 'DeviceListView' };
      }
    },
    children: [
      {
        path: 'subscriptions',
        name: 'ManageSubscriptionsView',
        component: ManageSubscriptionsView,
      },
      {
        path: 'subscriptions/:id',
        name: 'ManageSubscriptionView',
        component: ManageSubscriptionView,
      },
      {
        path: 'users',
        name: 'ManageUsersView',
        component: ManageUsersView,
      },
      {
        path: 'orders',
        name: 'ManageOrdersView',
        component: ManageOrdersView,
      },
      {
        path: 'orders/:id',
        name: 'ManageOrderView',
        component: ManageOrderView,
      },
      {
        path: 'addresses',
        name: 'ManageAddressesView',
        component: ManageAddressesView,
      },
      {
        path: 'payment',
        name: 'ManagePaymentView',
        component: ManagePaymentView,
      },
      {
        path: 'api',
        name: 'ManageAPIAccessView',
        component: ManageAPIAccessView,
        meta: { requestAccountAccess: 'has_api_access' },
      },
    ],
  },

  /** Account */
  {
    path: '/account',
    name: 'AccountSettingsView',
    component: AccountSettingsView,
  },

  /** Approve order */
  {
    path: '/approve-order-success',
    name: 'ApproveOrderSuccessView',
    component: ApproveOrderSuccessView,
  },
  {
    path: '/approve-order/:uuid',
    name: 'ApproveOrderView',
    component: ApproveOrderView,
  },

  /** Utility */
  {
    path: '/utility',
    name: 'UtilityView',
    component: UtilityView,
  },

  /** Legacy app redirects */
  ...legacyRedirects,

  /** 40x */
  {
    path: '/403',
    name: 'ForbiddenView',
    component: ForbiddenView,
  },
  {
    path: '/404',
    name: 'NotFoundView',
    component: NotFoundView,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(f, t, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      top: 0,
    };
  },
});

router.beforeEach(async (to, from) => {
  NProgress.start();
  const accountId = store.getters['auth/accountId'];
  const account = store.getters['auth/account'];
  const isB2bAccount = store.getters['auth/isB2B'];

  const deviceStore = useDevice();
  const { fetchByAccountId: fetchDevicesByAccountId } = deviceStore;
  const { devices } = deviceStore;

  const defaultStatuses = 'active,pending,pending-renewal,pending-cancellation';
  const subscriptionStatuses = isB2bAccount
    ? defaultStatuses.concat(',', 'cancelled')
    : defaultStatuses;

  const publicPages = [
    'PasswordForgotView',
    'PasswordResetView',
    'ApproveOrderView',
    'ApproveOrderSuccessView',
    'ShipmentTrackView',
    'ReportViewView',
    'CreateAccountView',
  ];

  if (publicPages.includes(to.name)) {
    return;
  }

  if (!store.getters['auth/check'] && to.name !== 'LoginView') {
    const { next } = useNextRoute(to);
    const query = next ? { next } : {};
    return {
      name: 'LoginView',
      query,
    };
  }

  if (
    to.meta?.requestAccountAccess &&
    !account[to.meta?.requestAccountAccess]
  ) {
    return {
      name: 'DeviceListView',
    };
  }

  /** Search history push handler */
  if (to.name === from.name) {
    return;
  }

  /** Special campaigns */
  fetchDevicesAndIccids({ accountId });

  if (to.name === 'DeviceListView') {
    const canSeeCancelledDevices = store.getters['auth/canSeeCancelledDevices'];
    const defaultStatuses =
      'active,pending,pending-renewal,pending-cancellation';
    const subscriptionStatuses = canSeeCancelledDevices
      ? defaultStatuses.concat(',', 'cancelled,blocked')
      : defaultStatuses;

    if (from.name === 'GroupListView') {
      return;
    }

    if (devices.size > 1) {
      return;
    }

    if (accountId) {
      await Promise.all([
        fetchPositionsByAccount(accountId),
        fetchDevicesByAccountId(accountId, {
          subscription_status: subscriptionStatuses,
        }),
        fetchGroups({ accountId }),
        fetchGeofences({ accountId }),
      ]);
    }
  }

  if (to.name === 'GroupListView') {
    if (!isB2bAccount) {
      return {
        name: 'DeviceListView',
      };
    }
    if (['DeviceListView', 'DeviceDetailsView'].includes(from.name)) {
      return;
    }
    await Promise.all([
      fetchPositionsByAccount(accountId),
      fetchDevicesByAccountId(accountId, {
        subscription_status: subscriptionStatuses,
      }),
      fetchGroups({ accountId }),
      fetchGeofences({ accountId }),
    ]);
  }

  if (to.name === 'DeviceDetailsView') {
    await fetchDeviceById(accountId, +to.params.id, {
      with_current_position: true,
    });
    if (
      [
        'DeviceListView',
        'GeofenceCreateView',
        'GroupListView',
        'GroupDetailsView',
      ].includes(from.name)
    ) {
      return;
    }
    await Promise.all([fetchGeofences({ accountId })]);
  }
  if (to.name === 'GroupDetailsView') {
    if (!isB2bAccount) {
      return {
        name: 'DeviceListView',
      };
    }
    await fetchGroup({ accountId, id: to.params.id });
    if (from.name === 'GroupListView') {
      return;
    }
    // if (from.name === 'DeviceDetailsView') {
    //   return;
    // }
    await Promise.all([
      fetchPositionsByAccount(accountId),
      fetchDevicesByAccountId(accountId, {
        subscription_status: subscriptionStatuses,
      }),
      fetchGeofences({ accountId }),
    ]);
  }

  if (to.name === 'GpxAdminDeviceDetailsView') {
    if (!store.getters['auth/isGpxAdmin']) {
      return {
        name: from.name,
      };
    }
    const { accountId, id } = to.params;
    await Promise.all([
      fetchGeofences({ accountId }),
      fetchDeviceById(+accountId, +id, {
        with_current_position: true,
        include: 'subscription',
      }),
    ]);
  }

  if (to.name === 'GeofenceListView') {
    if (
      [
        'GeofenceDetailsView',
        'GeofenceCreateView',
        'GeofenceEditView',
      ].includes(from.name)
    ) {
      return;
    }
    await Promise.all([
      fetchPositionsByAccount(accountId),
      fetchDevicesByAccountId(accountId, {
        subscription_status: subscriptionStatuses,
      }),
      fetchGeofences({ accountId }),
      fetchBleAnchors({ accountId }),
    ]);
  }

  if (to.name === 'GeofenceDetailsView') {
    await fetchGeofence({ accountId, id: to.params.id });
    if (
      [
        'GeofenceListView',
        'GeofenceEditView',
        'DeviceListView',
        'DeviceDetailsView',
        'GroupListView',
        'GroupDetailsView',
      ].includes(from.name)
    ) {
      return;
    }
    fetchPositionsByAccount(accountId);
    await fetchDevicesByAccountId(accountId, {
      subscription_status: subscriptionStatuses,
    });
    fetchGeofences({ accountId });
    fetchBleAnchors({ accountId });
  }

  if (to.name === 'GeofenceCreateView') {
    if (['DeviceDetailsView', 'GeofenceListView'].includes(from.name)) {
      return;
    }
    fetchBleAnchors({ accountId });
    await Promise.all([
      fetchPositionsByAccount(accountId),
      fetchDevicesByAccountId(accountId, {
        subscription_status: subscriptionStatuses,
      }),
      fetchGeofences({ accountId }),
    ]);
  }
  if (to.name === 'GeofenceEditView') {
    if (!['GeofenceDetailsView', 'GeofenceListView'].includes(from.name)) {
      return {
        name: 'GeofenceDetailsView',
        params: {
          id: to.params.id,
        },
      };
    }
  }

  if (to.name === 'ShipmentListView') {
    const accountId = store.getters['auth/accountId'];
    const dateFrom = subDays(new Date(), 15).toISOString();
    const dateTo = addDays(new Date(), 15).toISOString();
    if (from.name === 'ShipmentDetailsView') {
      return;
    }
    await store.dispatch('shipment/fetchByAccountId', {
      accountId,
      params: {
        with_geofences: true,
        with_current_position: true,
        with_path: true,
        include: 'device',
        date_from: dateFrom,
        date_to: dateTo,
      },
    });
  }

  if (to.name === 'ShipmentDetailsView') {
    const accountId = store.getters['auth/accountId'];
    const dateFrom = subDays(new Date(), 15).toISOString();
    const dateTo = addDays(new Date(), 15).toISOString();
    if (from.name === 'ShipmentListView') {
      return;
    }
    store.dispatch('shipment/fetchByAccountId', {
      accountId,
      params: {
        with_geofences: true,
        with_current_position: true,
        with_path: true,
        include: 'device',
        date_from: dateFrom,
        date_to: dateTo,
      },
    });
    await store.dispatch('shipment/fetchByAccountIdAndId', {
      accountId,
      id: to.params.id,
      params: {
        with_geofences: true,
        with_current_position: true,
        with_path: true,
        include: 'device',
      },
    });
  }

  if (to.name === 'ShipmentEditView') {
    const allowedFrom = ['ShipmentDetailsView', 'ShipmentListView'];
    if (!allowedFrom.includes(from.name)) {
      return {
        name: 'ShipmentDetailsView',
        params: {
          id: to.params.id,
        },
      };
    }
  }

  if (to.name === 'AlertsView') {
    await store.dispatch('settings/fetchByAccountId', { accountId });
  }

  if (to.name === 'ReportListView') {
    const accountId = store.getters['auth/accountId'];
    if (from.name === 'DeviceListView') {
      await store.dispatch('report/fetchByAccountId', {
        accountId,
        params: {
          include: 'device',
        },
      });
      return;
    }
    if (from.name === 'DeviceDetailsView') {
      await store.dispatch('report/fetchByAccountId', {
        accountId,
        params: {
          include: 'device',
        },
      });
      return;
    }
    if (from.name === 'ReportDetailsView') {
      return;
    }
    await Promise.all([
      fetchDevicesByAccountId(accountId, {
        subscription_status: 'active,pending-renewal',
      }),
      store.dispatch('report/fetchByAccountId', {
        accountId,
        params: {
          include: 'device',
        },
      }),
    ]);
  }

  if (to.name === 'ReportDetailsView') {
    const accountId = store.getters['auth/accountId'];
    if (!from.name) {
      await fetchDevicesByAccountId(accountId, {
        subscription_status: 'active,pending-renewal',
      });
      await Promise.all([
        store.dispatch('report/fetchByAccountIdAndId', {
          accountId,
          id: +to.params.id,
          params: {
            include: 'device',
          },
        }),
        store.dispatch('report/fetchByAccountId', {
          accountId,
          params: {
            include: 'device',
          },
        }),
      ]);
    }
  }

  if (to.name === 'ManageAddressesView') {
    await store.dispatch('address/fetchByAccountId', { accountId });
  }
});

router.afterEach(() => {
  NProgress.done();
  store.dispatch('settings/closeMobileNav');
});

export default router;
