<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import GroupListItem from './GroupListItem.vue';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import CommonBottomButtonSection from '@/components/_common/CommonBottomButtonSection.vue';
import { useSetItemIdOnDynamicList } from '@/use/use-set-item-id-on-recycle-list';
import { useGroup } from '@/composables/useGroup.ts';
import { storeToRefs } from 'pinia';

const props = defineProps<{
  groupIds: any[];
}>();

const store = useStore();
const accountId = store.getters['auth/accountId'];

const groupStore = useGroup();
const { selectedIds: selectedGroupIds } = storeToRefs(groupStore);
const { setSelectedIds, getById, setAccountId } = groupStore;

setAccountId(accountId);

const { editItemId, setEditItemId, resetEditItemId } =
  useSetItemIdOnDynamicList('group');

const selectedIds = computed({
  get() {
    return selectedGroupIds.value;
  },
  set(ids) {
    setSelectedIds(ids);
  },
});

function handleCreateClick() {
  store.dispatch('modal/open', {
    name: 'DeviceGroupModal',
    params: {
      actionType: 'create',
    },
  });
}
</script>

<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <div class="h-full">
    <CommonSearchBarSafeArea />
    <DynamicScroller
      class="h-[calc(100%-48px)] sm:h-[calc(100%-64px)]"
      :items="props.groupIds"
      :min-item-size="55"
      key-field="id"
      listClass="ds-group-list"
      itemClass="ds-group-list-item"
    >
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="getById(item)"
          :active="active"
          :size-dependencies="[getById(item)?.name]"
          :data-index="index"
        >
          <div class="border-b border-gray-100">
            <VLink
              block
              :to="`/groups/${item}`"
              class="bg-white hover:bg-gray-100"
            >
              <div class="flex py-[15px] pl-6 pr-[48px] md:pl-12 md:pr-[56px]">
                <div @click.stop="" class="mr-4 flex pt-[3px]">
                  <UICheckboxNewVModel :value="item" v-model="selectedIds" />
                </div>
                <GroupListItem
                  :group="getById(item)"
                  :edit-item-id="editItemId"
                  @setEditItemId="setEditItemId"
                  @resetEditItemId="resetEditItemId"
                />
              </div>
            </VLink>
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
  <CommonBottomButtonSection v-if="$can('manage', 'Groups') && props.groupIds.length">
    <UIButtonNew
      class="w-full"
      icon="SquaresPlusIcon"
      @click="handleCreateClick"
    >
      New Tracker Group
    </UIButtonNew>
  </CommonBottomButtonSection>
</template>

<style>
.ds-group-list {
  @apply !overflow-visible;
}
</style>
