import * as L from 'leaflet';
import { usePersonalLocalStorage } from '@/use/use-personal-local-storage';

export const getLayers = () => ({
  map: L.tileLayer(
    'https://api.maptiler.com/maps/streets/{z}/{x}/{y}@2x.png?key=9itgsP62snlBhRn8G4sH',
    { maxZoom: 20, tileSize: 512, zoomOffset: -1, minZoom: 1 },
  ),
  satellite: L.tileLayer(
    'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
    {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      maxZoom: 20,
    },
  ),
});

export function useLocalStorageLayer() {
  const id = usePersonalLocalStorage('layer', 'map');
  const set = (value) => (id.value = value);
  return {
    id: id.value,
    set,
  };
}
export function useLocalStorageZoom() {
  const zoom = usePersonalLocalStorage('zoom', null);
  const set = (value) => (zoom.value = JSON.stringify(value));
  const reset = () => (zoom.value = null);
  return {
    zoom: JSON.parse(zoom.value),
    set,
    reset,
  };
}
export function useLocalStorageOverlay(name, initialValue = true) {
  const is = usePersonalLocalStorage(name, initialValue);
  const set = (value) => (is.value = value);
  return {
    is: is.value,
    set,
  };
}
export function useLoadingOverlay({ fn, setOverlay, delay = 0 }) {
  setOverlay(true);
  setTimeout(() => {
    fn();
    setOverlay(false);
  }, delay);
}

export function addSeparator(position, label = '') {
  const container = document.querySelector('.leaflet-control-layers-overlays');
  const separator = document.createElement('div');
  separator.classList.add('leaflet-control-layers-separator');
  container.insertBefore(separator, container.childNodes[position]);
  const labelElement = document.createElement('span');
  labelElement.innerText = label;
  container.insertBefore(labelElement, container.childNodes[position + 1]);
}
