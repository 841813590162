import * as _ from 'lodash';
import service from '@/services';
import { VITE_APP_ADMIN_URL } from '@/config/environment';
import { sleep } from '@/utils/sleep';
import NProgress from 'nprogress';
import { setCookie, removeCookie } from '@/utils/cookie';
import * as events from '@/events';
import {
  resetLocalStorageKeys,
  resetSessionStorageKeys,
} from '@/utils/storage';
import { usePersonalLocalStorage } from '@/use/use-personal-local-storage';
import { ROLE } from '@/config/constants';
import { useLocalStorage } from '@vueuse/core';

export const state = {
  user: {},
  currentAccountId: null,
};

export const getters = {
  user: (state) => state.user,
  check: (state) => !!_.get(state.user, 'id'),
  accountSettingIsOn:
    (state, getters) =>
    (key, asString = false) => {
      const value = _.get(getters.accountSettings, key, 0);
      return asString ? !!value : !!+value;
    },
  accountSettings: (state, getters) => {
    const settings = getters.account?.settings || [];
    return _.reduce(
      settings,
      (aggregate, { code, value }) => {
        aggregate[code] = value;
        return aggregate;
      },
      {},
    );
  },
  intercomHash: (state) => {
    return state.intercomHash
  },
  account: (state) => {
    const {
      currentAccountId,
      user: { accounts },
    } = state;
    // if no accounts do nothing
    if (!accounts?.length) {
      return;
    }
    // if current accountId is not assigned, use the first account
    if (!currentAccountId) {
      return accounts[0];
    }
    // find the account
    const account = accounts.find((account) => account.id === currentAccountId);
    // if the account wasn't found. use the first account
    if (!account) {
      return accounts[0];
    }
    return account;
  },
  accountId: (state, getters) => getters.account?.id,
  isGpxAdmin: (state, getters) => getters.user.is_gpx_admin,
  isB2B: (state, getters) => {
    const is = getters.account?.is_b2b_account;
    return typeof is === 'boolean' ? is : true;
  },
  isAdminRole: (state, getters) => {
    return [ROLE.admin, ROLE.all].includes(getters['role']);
  },
  canSeeCancelledDevices: (state, getters) => {
    return getters.accountSettingIsOn('show_cancelled_devices');
  },
  canViewCustomReport: (state, getters) => {
    return (
      getters.accountSettingIsOn('embeds_custom_report') &&
      getters.accountSettingIsOn('embed_report_src', true)
    );
  },
  canUseBleFeature: (state, getters) => {
    return getters.accountSettingIsOn('ble_tags');
  },
  role: (state, getters) => {
    const {
      user: { account_roles },
    } = state;
    return account_roles[getters.accountId];
  },
};

export const mutations = {
  SET_CURRENT_USER: (state, user) => {
    if (_.isEmpty(state.user) || !_.isEqual(state.user, user)) {
      window.dataLayer.push({
        user,
      });
      state.user = user;
    }
  },
  UPDATE_CURRENT_USER: (state, user) => {
    state.user = {
      ...state.user,
      ...user,
    };
  },
  RESET_USER: (state) => {
    state.user = {};
    state.currentAccountId = null;
  },
  SET_INTERCOM_HASH: (state, hash) => {
    state.intercomHash = hash
  },
  SET_ACCOUNT_ID: (state, id) => {
    if (!_.isEqual(state.currentAccountId, id)) {
      state.currentAccountId = id;
      events.trigger(events.names.CURRENT_ACCOUNT_CHANGED);
    }
  },
  UPDATE_ACCOUNT_TOKEN: (state, { accountId, token }) => {
    const account = state.user.accounts.find(
      (account) => account.id === accountId,
    );
    if (!account) {
      return;
    }
    account.api_token = token;
  },
};

export const actions = {
  login: (state, { credentials, next }) => {
    return service.post('/token', credentials).then((res) => {
      const token = res.data?.token;
      if (token) {
        setCookie('token', token);
        events.trigger(events.names.LOGGED_IN, {
          $email: credentials.email,
        });
      }
      window.location.href = next ? `/?next=${next}` : '/';
    });
  },
  fetchTemporaryToken: () => {
    return service.post('/temporary-token').then((res) => res.data.token);
  },
  evaluateTemporaryToken: (state, { temporaryToken }) => {
    return service
      .put('/temporary-token', { token: temporaryToken })
      .then((res) => {
        if (res.data.imitation_token) {
          setCookie('imitation_token', res.data.imitation_token);
          return;
        }

        if (res.data.token) {
          setCookie('token', res.data.token);
          return;
        }
      });
  },
  setCurrentAccountId: ({ commit }, { accountId }) => {
    commit('SET_ACCOUNT_ID', accountId);
    const savedAccountId = usePersonalLocalStorage('accountId', null);
    savedAccountId.value = accountId;
  },
  fetchMe: ({ commit }, params) => {
    return service
      .get('/me')
      .then((res) => {
        // we received data
        const user = res.data.data;
        const { accounts, is_gpx_admin } = user;
        commit('SET_CURRENT_USER', user);

        if (user.intercom_hash) {
          commit('SET_INTERCOM_HASH', user.intercom_hash);
        }

        events.trigger(events.names.USER_LOADED);
        if (accounts?.length > 0) {
          // init state.currentAccountId
          // check if it has pre-selected an account
          let savedAccountId = { value: params?.accountId };
          if (!savedAccountId.value) {
            savedAccountId = usePersonalLocalStorage('accountId', null);
          }

          // check of the account belongs to user
          const isReal = accounts.find(
            (item) => item.id === Number(savedAccountId.value),
          );
          if (savedAccountId.value && isReal) {
            commit('SET_ACCOUNT_ID', Number(savedAccountId.value));
          } else {
            const accountId = user.accounts[0].id;
            commit('SET_ACCOUNT_ID', accountId);
            savedAccountId.value = accountId;
          }

          return res;
        }

        // we still need user data for approve-order because we need to save account's ACH info
        // but in case not signed in we'll just ignore it and not logout since user can still pay with stripe
        if (window.location.pathname.includes('approve-order')) {
          const promise = Promise.resolve('success');
          return promise;
        }

        if (is_gpx_admin) {
          return res;
        }

        this.logout().then(() => NProgress.done());
        return;
      })
      .catch(() => {});
  },
  logout: (state, callback) => {
    service
      .delete('/imitate')
      .then(() => service.delete('/token'))
      .then(async () => {
        removeCookie('token');
        removeCookie('imitation_token');
        resetLocalStorageKeys('all');
        events.trigger(events.names.LOGGED_OUT);
        if (callback) {
          callback();
          await sleep(3000);
        }
        location.href = '/';
      });
  },
  logoutImitation: () => {
    return service.delete('/imitate').then(() => {
      const imitateFrom = useLocalStorage('imitate_from');

      resetLocalStorageKeys('imitating_admin');

      if (imitateFrom?.value) {
        window.location.href = VITE_APP_ADMIN_URL + imitateFrom.value;
        imitateFrom.value = null;
      } else {
        window.location.href = VITE_APP_ADMIN_URL;
      }
      resetSessionStorageKeys('all');
    });
  },
  updateMe: ({ state }, data) => {
    const userId = state.user.id;
    return service.put(`/users/${userId}`, data);
  },
  updatePassword: (context, data) => {
    return service.post('/password', data);
  },
  sendForgotPasswordRequest: (_, data) => {
    return service.post('forgot-password', data);
  },
  sendResetPasswordRequest: (_, data) => {
    return service.put('reset-password', data);
  },
  resetToken: (store, { accountId }) => {
    return service.post(`accounts/${accountId}/reset-token`).then((res) => {
      store.commit('UPDATE_ACCOUNT_TOKEN', {
        accountId,
        token: res.data?.data?.api_token,
      });
    });
  },
  updateCurrentUser: (store, data) => {
    store.commit('UPDATE_CURRENT_USER', data);
  },
  resetCurrentUser: (store) => {
    store.commit('RESET_USER');
  },
  acceptInvitation: ({ commit }, { accountId, params }) => {
    return service
      .put(`/accounts/${accountId}/users/invite`, params)
      .then((res) => {
        if (res.data.token) {
          setCookie('token', res.data.token);
        }

        commit('SET_CURRENT_USER', res.data.data);
        events.trigger(events.names.USER_LOADED);
        return res;
      });
  },
};
