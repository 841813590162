<template>
  <CommonSearchBarSafeArea />
  <div class="h-[calc(100%-16px)] overflow-scroll">
    <GeofenceForm
      :initial-values="initialValues"
      :is-b2b="isB2B"
      @submit="submit"
      :readonly="!$can('manage', 'Geofences')"
    >
      <div
        v-if="$can('manage', 'Geofences')"
        class="w-full"
        @mouseenter="setSaveButtonHoverState(true)"
        @mouseleave="setSaveButtonHoverState(false)"
      >
        <UITooltip
          class="w-full"
          text="Must draw geofence on the map to save"
          :shown="shouldShowSaveHelp"
          :disabled="Boolean(shape)"
        >
          <UIButtonNew
            type="submit"
            size="sm"
            class="w-full"
            :class="[{ 'cursor-help': shouldShowSaveHelp }]"
            :disabled="!shape"
            :is-loading="isSaving"
            is-loading-text="Saving..."
          >
            Save
          </UIButtonNew>
        </UITooltip>
      </div>
    </GeofenceForm>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import GeofenceForm from '@/components/geofence/_common/GeofenceForm.vue';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import { COMMON_SAVE_ERROR, COMMON_SAVE_SUCCESS } from '@/config/constants';
import * as events from '@/events';

const toast = useToast();
const store = useStore();
const router = useRouter();
const route = useRoute();

const isSaving = ref(false);
const isSaveButtonHovered = ref(false);

const isB2B = store.getters['auth/isB2B'];
const id = +route.params.id;
const accountId = store.getters['auth/accountId'];
const geofence = computed(() => store.getters['geofence/byId'](id));
const shape = computed(() => store.getters['geofence/shape']);
const shouldShowSaveHelp = computed(() => {
  return !shape.value && isSaveButtonHovered.value;
});

onUnmounted(() => {
  store.dispatch('geofence/setViewportObj', null);
  store.dispatch('geofence/setShape', null);
});

const initialValues = {
  ...geofence.value,
  address: {
    label: geofence.value.address,
    lat: 1,
    lng: 1,
  },
  is_alertable: Boolean(geofence.value.is_alertable),
};

function submit(params) {
  isSaving.value = true;
  store
    .dispatch('geofence/update', { accountId, id, params })
    .then((res) => {
      router.replace({
        name: 'GeofenceDetailsView',
        params: { id: res.data.data.id },
      });
      events.trigger(events.names.GEOFENCE_CREATED, {
        id: res.data.data.id,
        ...params,
      });
      toast.success(COMMON_SAVE_SUCCESS);
    })
    .catch(() => {
      isSaving.value = false;
      toast.error(COMMON_SAVE_ERROR);
    });
}

function setSaveButtonHoverState(value) {
  if (shape.value) return;
  isSaveButtonHovered.value = value;
}
</script>
