import { defineAbility } from '@casl/ability';
import store from './store';

export default () => defineAbility((can, cannot) => {
    const user = store.getters['auth/user'];
    if (!user) return;

    const account = store.getters['auth/account'];
    if (!account) return;

    const accountSettingIsOn = store.getters['auth/accountSettingIsOn'];
    const role = user.account_roles[account.id];

    if (['admin', 'all'].indexOf(role) > -1) {
      accountSettingIsOn('dashboards') && can('view', 'Dashboard');
      can('manage', 'Users');
      can('manage', 'Account');
    }
    if (role !== 'viewer') {
      can('manage', 'Shipments');
      can('manage', 'Devices');
      can('manage', 'Groups');
      can('manage', 'Geofences');
      can('manage', 'Anchors');
      can('manage', 'Reports');
      can('manage', 'Rules');
    }

});
