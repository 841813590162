<script setup>
import RadioGroup from "../elements/RadioGroup.vue";
import RadioGroupOption from "../elements/RadioGroupOption.vue";
import DeviceAutocomplete from "./DeviceAutocomplete.vue";
import { getAccountDevices } from "@gpx-spa/queries";
import Spinner from "../elements/Spinner.vue";

const role = defineModel("role", "all");
const deviceIds = defineModel("deviceIds");
const props = defineProps(['accountId'])

const { data: accountDevices, isFetching: isFetchingDevices } = getAccountDevices(props.accountId);
</script>

<template>
  <div>
    <RadioGroup class="mt-[19px]">
      <RadioGroupOption
          v-model="role"
          :value="'admin'"
          :label="'Administrator'"
          :description="'Access to all devices, can invite users'"
      />
      <RadioGroupOption
          v-model="role"
          :value="'all'"
          :label="'All Devices'"
          :description="'Access to all devices, can not invite users'"
      />
      <RadioGroupOption
          v-model="role"
          :value="'viewer'"
          :label="'Viewer'"
          :description="'Read only access'"
      />
      <RadioGroupOption
          v-model="role"
          :value="'devices'"
          :label="'Specific Devices'"
          :description="'Access only specific devices'"
      />
    </RadioGroup>
    <div class="mt-[29px]"
        v-if="role === 'devices'"
    >
      <Spinner v-if="isFetchingDevices" />
      <DeviceAutocomplete
          v-else
          v-model="deviceIds"
          :devices="accountDevices"
      >
      </DeviceAutocomplete>
    </div>
  </div>
</template>