<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PaginatedTableVue from '@/components/_common/Data/mixins/PaginatedTable.vue';
import { formatTime } from '@/filters';
import { useAbility } from '@casl/vue';

export default {
  props: {
    accountId: {
      required: true,
      type: Number,
    },
  },

  mixins: [PaginatedTableVue],

  data() {
    return {
      title: 'Rules',
      subtitle: 'View, edit your rules list',
      columns: ['Rule name', 'Created At'],
    };
  },

  computed: {
    ...mapGetters({
      itemsByIds: 'rule/byIds',
    }),
    topButton() {
      const { can } = useAbility();

      if (!can('manage', 'Rules')) return null;
      return {
        label: 'New Rule',
        action: {
          mode: 'route',
          route: '/rules/create',
        },
      };
    },
  },

  watch: {
    accountId() {
      this.loadPage();
    },
  },

  methods: {
    formatTime,
    ...mapActions({
      fetchRules: 'rule/fetch',
    }),
    fetchItems(pagination) {
      const params = _.assign({}, pagination);
      return this.fetchRules(params);
    },
    formatItemIntoRow(rule) {
      return {
        id: rule.id,
        name: rule.name,
        date: formatTime(rule.created_at),
      };
    },
    onItemClick(item) {
      this.$router.push({
        name: 'rules.edit',
        params: { id: item.id },
      });
    },
  },
};
</script>

<style></style>
