<template>
  <div class="relative">
    <label
        v-if="label"
        class="mb-[3px] block text-xs text-gpx_xs font-medium text-gpx_gray-200"
        :for="id"
    >{{ label }}</label
    >
    <div
        class="relative flex"
        v-click-outside="() => (this.showDropdown = false)"
    >
      <input
          :id="id"
          :disabled="disabled || loading"
          :placeholder="placeholder"
          v-model="query"
          @keyup="debouncedRefreshOptions"
          @click="handleFocus"
          class="w-full rounded-[6px] border-0 bg-bg-3 bg-bg-2 px-[22px] py-[14px] text-sm text-gpx_sm text-lms_black-100 text-gpx_black-500 placeholder:text-gpx_gray-300 focus:outline-0 focus:ring-0"
          :class="[
          {
            '!bg-white drop-shadow-[2px_4px_8px_0_rgba(232,_234,_237,_0.2)]': light,
            'bg-red-150': hasError,
            'cursor-not-allowed text-gpx_gray-600': disabled
          },
        ]"
      />

      <div
          class="absolute right-0 top-0 flex h-full cursor-pointer items-center justify-center px-[16px]"
          :class="{ '!pointer-events-none !cursor-not-allowed': disabled }"
      >
        <div
            v-if="noMatches && !selected"
            class="mt-[8px] text-sm text-red-300"
        >
          <span>No results</span>
        </div>
        <div v-else>
          <Spinner v-if="fetching || loading" />
          <component
              v-else
              :is="iconChevron"
              class="heroicon-stroke-w-1.4 size-[20px] text-gpx_gray-300"
              @click.stop="openCloseClick"
          />
        </div>
      </div>

      <SelectMenu
          v-if="showDropdown"
          :options="filteredOptions"
          :selected="selected"
          @close="selectOption($event)"
      />
    </div>
    <div v-if="multiple" class="mt-[16px] flex flex-wrap gap-[8px]">
      <Remove
          v-for="(item, i) in selectedMultiple"
          :key="'remove-' + i"
          :label="item.label"
          :value="item.value"
          gray
          @remove="removeSelected(item)"
      />
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { nextTick } from 'vue';
import vClickOutside from 'click-outside-vue3';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline';
import SelectMenu from './SelectMenu.vue';
import Spinner from './Spinner.vue';
import Remove from './Remove.vue';

export default {
  emits: ['update:modelValue'],
  expose: [
    'setSelectedOptionQuietly',
    'setSelectedOption',
    'setSelectedOptionFromOutside',
    'refreshOptions',
  ],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    loading: Boolean,
    light: {
      required: false,
      default: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    modelValue: {
      required: false,
      type: [Object, Array],
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    options: [Array, Function, Object],
    selectedOptions: Array,
    multiple: Boolean,
    openOnFocus: Boolean,
  },
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    SelectMenu,
    Spinner,
    Remove,
  },
  computed: {
    noMatches() {
      return !this.fetching && this.query && this.filteredOptions.length === 0;
    },
    filteredOptions() {
      return this.optionsCache.filter((option) => {
        return _.some(this.selectedMultiple, option) === false;
      });
    },
    iconChevron() {
      return this.showDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon';
    },
  },
  created() {
    this.debouncedRefreshOptions = _.debounce(this.refreshOptions, 150);
  },

  watch: {
    query: {
      handler(n) {
        if (this.multiple) {
          return;
        }

        if (this.options.length && !_.some(this.options, ['label', n])) {
          this.selected = null;
          this.$emit('update:modelValue', null);
        }
      },
    },
    modelValue: {
      handler(n) {
        if (_.isEqual(this.selected, n)) {
          return;
        }

        this.setSelectedOptionFromOutside(n);
        if (this.showDropdown) {
          this.showDropdown = false;
        }
      },
    },
  },

  methods: {
    openCloseClick() {
      if (!this.showDropdown) {
        this.handleFocus();
      } else {
        this.showDropdown = false;
      }
    },
    handleFocus() {
      if (this.openOnFocus) {
        this.refreshOptions();
      }
    },
    refreshOptions() {
      const query = _.trim(this.query);
      this.showDropdown = this.openOnFocus || !!query;
      if (typeof this.options === 'function' && !this.fetching) {
        if (!query) {
          this.optionsCache = [];
          return;
        }

        this.fetching = true;
        this.options({ query })
            .then((options) => {
              this.optionsCache = options;
              this.showDropdown = this.filteredOptions.length > 0;
            })
            .finally(() => (this.fetching = false));
        return;
      }

      if (Array.isArray(this.options)) {
        this.optionsCache = this.options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });
      }
    },
    selectOption(option) {
      if (!option) {
        return;
      }

      if (_.some(this.selectedMultiple, option)) {
        this.showDropdown = !this.showDropdown;
        return;
      }

      if (this.showDropdown) {
        nextTick(() => {
          this.showDropdown = false;
        });
      }

      this.setSelectedOption(option);
    },
    removeSelected(item) {
      _.pull(this.selectedMultiple, item);
      this.$emit('update:modelValue', this.selectedMultiple);
    },
    setSelectedOptionQuietly(option) {
      // workaround to prevent showing noMatch computed
      if (!this.optionsCache?.length) {
        this.optionsCache = ['not-empty'];
      }

      if (this.multiple) {
        this.selectedMultiple.push(option);
        this.query = '';
        return;
      }

      this.query = option.label;
      this.selected = option;
      this.emitInput();
    },
    setSelectedOption(option) {
      if (this.multiple) {
        this.setSelectedOptionQuietly(option);
        this.$emit('update:modelValue', this.selectedMultiple);
        return;
      }

      this.setSelectedOptionQuietly(option);
      this.$emit('update:modelValue', option);
    },
    setSelectedOptionFromOutside(option) {
      this.query = option?.label || '';
      this.selected = option;
      this.emitInput();
    },
    emitInput() {
      this.$emit(
          'update:modelValue',
          this.multiple ? this.computedOptions : this.selected,
      );
    },
  },
  data: () => {
    return {
      id: null,
      query: '',
      optionsCache: [],
      showDropdown: false,
      selected: null,
      selectedMultiple: [],
      fetching: false,
    };
  },
  beforeMount() {
    this.id = this._uid;
    this.query = _.get(this.modelValue, 'label', '');
    this.selectedMultiple = this.selectedOptions ? this.selectedOptions : [];
    
    if (this.multiple && this.selectedMultiple.length > 0) {
      this.$nextTick(() =>
          this.$emit('update:modelValue', this.selectedMultiple),
      );
    }
  },
};
</script>
