<template>
  <div
      class="text-gpx_black-300 absolute right-0 top-full z-30 max-h-80 w-full min-w-[160px] overflow-scroll rounded-[6px] border border-gpx_gray-900 bg-white py-[8px] text-xs drop-shadow-main translate-y-[8px]"
      v-click-outside="close"
  >
    <template v-if="options">
      <SelectMenuItem
          v-for="item in options"
          :key="getKey(item)"
          :option="item"
          :selected="isEqual(item, selected)"
          :can-delete="canDelete"
          @select="selectOption($event)"
      />
    </template>
    <div v-if="options.length < 1" class="px-2 text-red-300">No options</div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import SelectMenuItem from './SelectMenuItem.vue';
import vClickOutside from 'click-outside-vue3';

export default {

  components: {
    SelectMenuItem,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  emits: ['close'],

  props: {
    options: Array,
    selected: [String, Number, Object],
    canDelete: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    isEqual: _.isEqual,
    getKey(item) {
      return typeof item === 'object' ? item.value : item;
    },
    selectOption(option) {
      this.$emit('close', option);
    },
    close() {
      this.$emit('close', this.selected);
    },
  },
};
</script>
