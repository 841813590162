<script setup lang="ts">
import { useStore } from 'vuex';
import BaseLayout from '@/layouts/BaseLayout.vue';
import VButton from '@/components/ui/VButton.vue';
import VSelect from '@/components/ui/VSelect.vue';
import VReportTableItem from '@/components/report/list/ReportListItem.vue';
import { setTitle } from '@/utils/title';
import { useToast } from 'vue-toastification';
import { storeToRefs } from 'pinia';
import { COMMON_SAVE_SUCCESS } from '@/config/constants';
import { useDevice } from '@/composables/useDevice';
import { computed, reactive } from 'vue';

const toast = useToast();

setTitle('Reports');

const data = reactive({
  form: {
    deviceId: null,
    date: [],
  },
  isSaving: false,
  columns: ['Tracker', 'Date Range', 'Public?', 'Public Link'],
});

const store = useStore();
const deviceStore = useDevice();
const { devices } = storeToRefs(deviceStore);

const accountId = computed(() => store.getters['auth/accountId']);
const reports = computed(() => store.getters['report/all']);

const deviceOptions = computed(() => {
  const result = [];
  devices.value.forEach((device) => {
    result.push({ label: device.name, value: device.id });
  });
  return result;
});

const isSaveDisabled = computed(() => !data.form.deviceId || data.isSaving);

const handleSave = () => {
  data.isSaving = true;
  store
    .dispatch('report/store', {
      accountId: accountId.value,
      params: {
        include: 'device',
        device_id: data.form.deviceId,
        start_at: data.form.date[0],
        end_at: data.form.date[1],
      },
    })
    .then(() => {
      data.form.deviceId = null;
      toast.success(COMMON_SAVE_SUCCESS);
    })
    .finally(() => (data.isSaving = false));
};
</script>
<template>
  <BaseLayout>
    <h4 class="mb-2 text-2xl font-medium leading-tight">Reports</h4>
    <div class="flex flex-wrap items-center"
         v-if="$can('manage', 'Reports')"
    >
      <div class="mb-4 mr-6 w-full sm:mb-0 sm:w-auto sm:min-w-[220px]">
        <VSelect
          variant="sm:flex items-center"
          size="sm"
          v-model="data.form.deviceId"
          :options="deviceOptions"
        >
          <span class="mb-2 inline-block sm:mb-0 sm:mr-4">Tracker:</span>
        </VSelect>
      </div>
      <div class="mb-5 mr-2 w-full sm:mb-0 sm:w-auto">
        <div class="flex flex-wrap items-center sm:flex-nowrap">
          <span
            class="mb-2 block w-full sm:mb-0 sm:mr-4 sm:inline-block sm:w-auto"
          >
            Date range:
          </span>
          <VDatePickerRange
            :show-tools="false"
            v-model="data.form.date"
            :maxDate="true"
            tooltip="Select range"
            utc
          />
        </div>
      </div>
      <VButton
        variant="primary"
        size="sm"
        :isLoading="data.isSaving"
        loadingText="Saving..."
        :disabled="isSaveDisabled"
        @click.prevent="handleSave"
      >
        Save report
      </VButton>
    </div>
    <div v-if="!reports.length" class="py-12 text-center">
      <h3 class="text-2xl leading-tight">No reports have been created</h3>
    </div>
    <table v-else class="mt-12 table w-full">
      <thead
        class="hidden bg-[#f9f9f9] text-left text-base leading-[1.5] text-gray-1000 sm:table-header-group"
      >
        <tr>
          <th class="border-y border-[#dee2e6] p-[12px] font-medium">
            Tracker
          </th>
          <th class="border-y border-[#dee2e6] p-[12px] font-medium">
            Date Range
          </th>
          <th class="border-y border-[#dee2e6] p-[12px] font-medium">
            Public?
          </th>
          <th class="border-y border-[#dee2e6] p-[12px] font-medium">
            Public Link
          </th>
        </tr>
      </thead>
      <tbody
        class="flex flex-col border-t border-[#dee2e6] sm:table-row-group sm:border-t-0"
      >
        <VReportTableItem
          v-for="report in reports"
          :key="report.id"
          :report="report"
        />
      </tbody>
    </table>
  </BaseLayout>
</template>
