<template>
  <tr class="flex flex-col border-b border-[#dee2e6] py-[16px] sm:table-row">
    <td
      class="pb-[10px] before:block before:font-medium before:content-['Tracker:'] sm:p-[12px] sm:before:hidden"
    >
      <router-link
        class="relative text-[#ee3c30] after:absolute after:inset-x-0 after:bottom-[-1px] after:block after:border-b after:border-b-[#c21b10] after:opacity-0 hover:text-[#c21b10] hover:after:opacity-100"
        :to="{ name: 'ReportDetailsView', params: { id: report.id } }"
      >
        {{ deviceName }}
      </router-link>
    </td>
    <td
      class="pb-[10px] before:block before:font-medium before:content-['Date_Range:'] sm:p-[12px] sm:before:hidden"
    >
      <span>
        {{ startAt }} to
        {{ endAt }}
      </span>
    </td>
    <td
      class="pb-[10px] before:inline-block before:font-medium before:content-['Public?'] sm:p-[12px] sm:before:hidden"
    >
      <UIToggle :disabled="!$can('manage', 'Reports')" v-model="isPublic" size="sm" />
    </td>
    <td
      class="pb-[10px] before:inline-block before:font-medium before:content-['Public_Link:'] sm:p-[12px] sm:before:hidden"
    >
      <VInputCopy
        size="sm"
        type="text"
        v-model="uuidLink"
        :disabled="!uuidLink"
      />
    </td>
  </tr>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import UIToggle from '../../ui/UIToggle.vue';
import VInputCopy from '../../ui/VInputCopy.vue';
import { formatTime } from '@/filters';

export default {
  components: { UIToggle, VInputCopy },

  props: {
    report: Object,
  },

  data() {
    return {
      isPublic: undefined,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
    }),
    uuidLink() {
      return this.report.is_public
        ? `${window.location.origin}/reports/view/${this.report.uuid}`
        : null;
    },
    deviceName() {
      return _.get(this.report, 'device.name', 'No name');
    },
    startAt() {
      const startAt = _.get(this.report, 'start_at', null);
      if (startAt) {
        return this.formatReportDate(startAt);
      }
      return '-';
    },
    endAt() {
      const endAt = _.get(this.report, 'end_at', null);
      if (endAt) {
        return this.formatReportDate(endAt);
      }
      return '-';
    },
  },

  watch: {
    isPublic: {
      handler(n, o) {
        if (_.isUndefined(o)) return;
        this.togglePublic(n);
      },
    },
  },

  mounted() {
    this.togglePublic = _.debounce(this.togglePublic, 600);

    this.isPublic = this.report.is_public;
    this.publicLink = 'link to copy';
  },

  methods: {
    ...mapActions({
      update: 'report/update',
    }),
    togglePublic(value) {
      this.update({
        accountId: this.accountId,
        id: this.report.id,
        params: {
          is_public: value,
        },
      });
    },
    formatReportDate(date) {
      if (!date) return;
      return formatTime(date, 'E, MMM do, yyyy');
    },
  },
};
</script>
