<template>
  <div>
    <div class="mb-1 flex items-center gap-x-2">
      <div class="text-[15px] font-medium text-lms_black-300">{{ name }}</div>
      <VBadge :status="status" />
    </div>
    <div class="text-[13px] text-lgmx_gray-900">
      <span>{{ startAt }}</span>
      <span>&nbsp;to&nbsp;</span>
      <span>{{ scheduledEndAt }}</span>
    </div>
    <div class="text-[13px] text-lgmx_gray-900">
      <span>{{ deviceName }}</span>
      <span>&nbsp;going from&nbsp;</span>
      <span class="hidden sm:inline">{{ fromAddress }}</span>
      <span class="inline sm:hidden">{{ fromAddressTruncated }}</span>
      <span>&nbsp;to&nbsp;</span>
      <span class="hidden sm:inline">{{ toAddress }}</span>
      <span class="inline sm:hidden">{{ toAddressTruncated }}</span>
    </div>
    <UIIconButton
      v-if="$can('manage', 'Shipments')"
      class="absolute right-[9px] top-[11px] ml-[6px] md:right-[16px]"
      icon="EllipsisVerticalIcon"
      @click="$emit('setEditItemId', shipment.id)"
    >
      <UIActions
        :id="'shipment-actions-' + shipment.id"
        v-if="editItemId === shipment.id"
        v-click-outside="() => $emit('resetEditItemId')"
        :editItemId="editItemId"
        :actions="actions"
      />
    </UIIconButton>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { mapActions } from 'vuex';
import VBadge from '@/components/ui/VBadge.vue';
import { formatTime } from '@/filters';

export default {
  components: {
    VBadge,
  },

  emits: ['setEditItemId', 'resetEditItemId'],
  props: {
    shipment: {
      type: Object,
    },
    actions: {
      type: Array,
      required: true,
    },
    editItemId: Number,
  },

  computed: {
    name() {
      return _.get(this.shipment, 'name', '-');
    },
    deviceName() {
      return _.get(this.shipment, 'device.name', '-');
    },
    status() {
      return _.get(this.shipment, 'status', 'unknown');
    },
    startAt() {
      const startAt = _.get(this.shipment, 'start_at', null);
      if (startAt) {
        return formatTime(startAt, 'M/d h:mm aa');
      }
      return '-';
    },
    scheduledEndAt() {
      const endAt = _.get(this.shipment, 'end_at', null);
      if (endAt) {
        return formatTime(endAt, 'M/d h:mm aa');
      }
      return '-';
    },
    fromAddress() {
      const id = _.get(this.shipment, 'from_geofence_id', null);
      if (id) {
        return _.get(this.shipment, 'from_geofence.address', '-');
      } else {
        return _.get(this.shipment, 'from_address.name', '-');
      }
    },
    fromAddressTruncated() {
      return _.truncate(this.fromAddress);
    },
    toAddressTruncated() {
      return _.truncate(this.toAddress);
    },
    toAddress() {
      const id = _.get(this.shipment, 'to_geofence_id', null);
      if (id) {
        return _.get(this.shipment, 'to_geofence.address', '-');
      } else {
        return _.get(this.shipment, 'to_address.name', '-');
      }
    },
  },

  methods: {
    ...mapActions({
      openModal: 'modal/open',
      setSelectedIds: 'shipment/setSelectedIds',
      archiveShipments: 'shipment/bulkArchive',
      deleteShipments: 'shipment/bulkDelete',
    }),
  },
};
</script>
